import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import styled from "styled-components";

import LazyLoad from "react-lazyload";

import siteConfig from "../../data/SiteConfig";
import { useStaticDomain, forceHttps, getPerformerSortName } from "../utils/strings";

import Page from "../components/Layout/Page";
import {
  FixedWidthContainer,
  FullWidthContainer
} from "../components/Layout/ContentContainers";
import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

import PerformerList from "../components/PerformerList";

class WrestlersPage extends React.Component {
  getMetaUrl() {
    const { location } = this.props;
    return `${siteConfig.siteUrl}${location.pathname}` || siteConfig.siteUrl;
  }

  getMetaTitle() {
    let pageTitle = siteConfig.siteTitle;

    pageTitle = `FEST Wrestling | ${pageTitle}`;

    return pageTitle;
  }

  render() {
    const { data } = this.props;


    const performers = data.wrestlers.edges.sort((a, b) => {
      const aName = a.node.title.rendered;
      const bName = b.node.title.rendered;
      if (aName === bName) {
        return 0;
      }

      return getPerformerSortName(aName) > getPerformerSortName(bName) ? 1 : -1;
    });


    if (performers.length < 1) {
      return (
        <Page title="Fest Wrestling">
          <Helmet title={this.getMetaTitle()}>
            <meta property="og:url" content={this.getMetaUrl()} />
            <meta property="og:title" content={this.getMetaTitle()} />
          </Helmet>

          <FixedWidthContainer
            style={{ margin: "4rem auto 0", textAlign: "center" }}
          >
            <LazyLoad>
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/mHzwbxkosYE"
                  width="560"
                  height="315"
                  frameBorder="0"
                  allowFullScreen="allowfullscreen"
                  title="FEST Wrestling 2021 Intro"
                />
              </div>
            </LazyLoad>
          </FixedWidthContainer>
          <FixedWidthContainer>
            <WrestlingContent
              dangerouslySetInnerHTML={{
                __html: forceHttps(
                  data.festWrestlingPageContent.edges[0].node.content
                )
              }}
            />
          </FixedWidthContainer>
        </Page>
      );
    }



    return (
      <Page title="Fest Wrestling">
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>

        {/* 
        <FullWidthContainer
          style={{ margin: "3rem auto 0", textAlign: "center" }}
        >
          <WrestlingHeadline>
            <img
              alt="The FEST est. 2002 logo"
              src={require(`../assets/images/fest-wrestling/WE_ARE_FAMILY_HEADER_LOGO_960x411.jpg`)}
            />
          </WrestlingHeadline>
        </FullWidthContainer>
        */}

        <FixedWidthContainer>
          <WrestlingHeadline style={{ textAlign: "center" }}>
            &ldquo;WE ARE FAMILY&rdquo; SCHEDULE
          </WrestlingHeadline>
          <WrestlingSchedule>
            <WrestlingContent
              dangerouslySetInnerHTML={{
                __html: forceHttps(
                  this.props.data.festWrestlingSchedule.edges[0].node.content
                )
              }}
            />
            <br />
            <a href="/schedule/">
              <strong>VIEW THE FULL #FEST22 SCHEDULE HERE!!!</strong>
            </a>
          </WrestlingSchedule>
        </FixedWidthContainer>

        <FullWidthContainer
          style={{ marginBottom: "4rem", textAlign: "center" }}
        >
          <WrestlingHeadline>THIS YEAR&rsquo;S LINEUP</WrestlingHeadline>
          <LazyLoad>
            {performers.length > 0 ? (
              <PerformerListContainer>
                <PerformerList
                  performers={performers}
                  urlPrefix="fest-wrestling"
                />
              </PerformerListContainer>
            ) : (
              <div>
                <p>The FEST Wrestling lineup will be announced JULY 8th!!!</p>
              </div>
            )}
          </LazyLoad>
        </FullWidthContainer>

        <FixedWidthContainer>
          <WrestlingContent
            dangerouslySetInnerHTML={{
              __html: useStaticDomain(forceHttps(
                data.festWrestlingPageContent.edges[0].node.content)
              )
            }}
          />
        </FixedWidthContainer>
      </Page>
    );
  }
}

const PerformerListContainer = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    max-width: 95%;
    margin: 0 auto;
  `};
`;

const WrestlingContent = styled.div`
  margin-top: 1rem;
  img {
    height: auto;
    max-width: 100%;
  }
`;

const WrestlingHeadline = styled.h2`
  margin: 2rem 0 1rem;
`;

const WrestlingSchedule = styled.div`
  h5 {
    margin-bottom: 0.5rem;
  }
  ul {
    list-style: inherit;
    margin-left: 3rem;
    margin-bottom: 0.5rem;

    h6 {
      font-size: 0.9rem;
    }
  }
`;

export default WrestlersPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query WrestlersPageQuery {
    festWrestlingPageContent: allWordpressPage(
      filter: { slug: { eq: "fest-wrestling" } }
    ) {
      edges {
        node {
          title
          slug
          content
        }
      }
    }
    festWrestlingSchedule: allWordpressPage(
      filter: { slug: { eq: "fest-wrestling-schedule" } }
    ) {
      edges {
        node {
          title
          slug
          content
        }
      }
    }
    wrestlers: allFestPerformers(
      filter: { acf: { type: { eq: "wrestler" } } }
    ) {
      edges {
        node {
          id
          festId
          title {
            rendered
          }
          slug
          acf {
            hometown
            photo {
              url
              sizes {
                medium
                medium_width
                medium_height
              }
            }
          }
        }
      }
    }
  }
`;
